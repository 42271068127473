import { CLASSICAL_NAME, JAZZ_NAME, UNIVERSE_AS_FILTER } from "utils/nameVariables";

//
export const getPersonDetailsUrl = (id, lang, universe) => `${process.env.REACT_APP_VIALMA_API}/artists/${id}/?lang=${lang}&universe=${universe}`;
export const fetchVideosUrl = (page, count, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/videos?page=${page}${count ? `&limit=${count}` : ""}&lang=${lang}&universe=${universe}`;
export const fetchFeaturedVideosUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/videos-with-feature?lang=${lang}`;
export const fetchVideoCategoriesUrl = (lang, universe, anonymous) =>
  `${process.env.REACT_APP_VIALMA_API}/videos_categories?lang=${lang}&universe=${universe}${anonymous ? "&anonymous=true" : ""}`;
export const getUserCountryUrl = `${process.env.REACT_APP_VIALMA_API}/country/country`;

//Quiz Urls
//getQuizContentUrl is the default onboarding quiz
export const getWelcomeQuiz = (lang, universeStr) => `${process.env.REACT_APP_VIALMA_API}/quiz/main-quiz?lang=${lang}${universeStr}`;
export const submitQuizResultUrl = (lang, slug) => `${process.env.REACT_APP_VIALMA_API}/quiz/result/update?${slug}&lang=${lang}`;
export const getQuizContentByTypeUrl = (lang, type, universeStr) =>
  `${process.env.REACT_APP_VIALMA_API}/quiz/page/${type}?lang=${lang}${universeStr}`;

//Newsfeed Urls
export const worksUrl = (id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/oeuvres/${id}/interpretations?lang=${lang}&version=2&universe=${universe ? universe : CLASSICAL_NAME}`;
export const playlistUrl = (id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/radios/${id}?lang=${lang}&ver=2&universe=${universe ? universe : CLASSICAL_NAME}`;
export const articleUrl = (id, lang, universe, universeAsFilter) =>
  `${process.env.REACT_APP_VIALMA_API_2}/news/${id}?lang=${lang}${universeAsFilter ? UNIVERSE_AS_FILTER : `&universe=${universe}`}`;
export const albumUrl = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/album/${id}?lang=${lang}&universeAsFilter=true`;
export const interpretationUrl = (workId, personId, year, lang) =>
  `${process.env.REACT_APP_VIALMA_API}/oeuvres/${workId}/interpretations?lang=${lang}&universe=Classical&artist=${personId}&year=${year}`;
export const podcastUrl = (podcastId, lang, universe) => `${process.env.REACT_APP_VIALMA_API}/podcast/${podcastId}?lang=${lang}&universe=${universe}`;
export const chapterUrl = (chapterId, lang, universe) => `${process.env.REACT_APP_VIALMA_API}/chapter/${chapterId}?lang=${lang}&universe=${universe}`;
export const jumpBackInUrl = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/customers/recently-listened-history?lang=${lang}&universe=${universe}${limit ? `&limit=${limit}` : ""}`;

//Person/Artist Profile Page Urls
export const recentAlbumsUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API_2}/albums/recent/${personId}?lang=${lang}&universe=${universe}`;
export const topTracksUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/top_tracks?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const performedWithUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/similar?lang=${lang}&universe=${universe}`;
export const appearsOnPlaylistUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/appears_on_playlists?lang=${lang}&universe=${universe}`;
export const appearsOnVideoUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/appears_on_videos?lang=${lang}&universe=${universe}`;
export const appearsOnArticlesUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/appears_on_news?lang=${lang}&universe=${universe}`;
export const appearsOnPodcastsUrl = (personSlug, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/search-mozart?term=${personSlug}&indexes[]=podcasts&universe=${universe}&lang=${lang}`;
export const getWorksUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/compositeurs/${personId}/oeuvres?lang=${lang}&universe=${universe}`;
export const getRecordingsUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/recordings?lang=${lang}&universe=${universe}`;
export const getAlbumsUrl = (personId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/${personId}/albums?lang=${lang}&universe=${universe}`;
export const getAlbumAlsoLikeUrl = (albumId, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/album/${albumId}/recommendedContent?lang=${lang}&universe=${universe}`;

export const getInterpretationsUrl = (workId, artistId, recordYear, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/oeuvres/${workId}/interpretations?lang=${lang}&universe=${universe}${artistId ? `&artist=${artistId}` : ""}${
    recordYear ? `&year=${recordYear}` : ""
  }`;
export const getComposersUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/compositeurs?lang=${lang}`;

//Articles Urls
export const fetchArticlesUrl = (lang, universe, count, range) =>
  `${process.env.REACT_APP_VIALMA_API_2}/news_cat?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${count ? `&page=${count}` : ""}${
    range ? `&range=${range}` : ""
  }`;

export const fetchArticlesCategoriesUrl = (lang, universe, range) =>
  `${process.env.REACT_APP_VIALMA_API_2}/news_cat?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${range ? `&range=${range}` : ""}`;

export const fetchFeaturedArticlesUrl = (lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API_2}/news-with-feature?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const fetchArticleDetailsUrl = (id, lang, universe) => `${process.env.REACT_APP_VIALMA_API_2}/news/${id}?lang=${lang}${universe}`;

//Search
export const getSearchResultsUrl = (searchText, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/search-mozart?term=${searchText}&indexes[]=artists${
    universe === CLASSICAL_NAME ? "&indexes[]=composers&indexes[]=works" : ""
  }&indexes[]=albums&indexes[]=playlists&indexes[]=videos&indexes[]=podcasts&indexes[]=episodes&indexes[]=news&indexes[]=partners&universe=${
    universe ? universe : CLASSICAL_NAME
  }&lang=${lang}`;

//Video Details
export const getVideoDetailsUrl = (id, lang, universe) => `${process.env.REACT_APP_VIALMA_API}/videos/${id}?lang=${lang}${universe}`;

//Playlists Urls
export const getPlaylistsUrl = (page, lang, universe, anonymous) =>
  `${process.env.REACT_APP_VIALMA_API}/radios_cat?lang=${lang}&page=${page ? page : 1}&universe=${universe ? universe : CLASSICAL_NAME}${
    anonymous ? "&anonymous=true" : ""
  }`;
export const getPlaylistsDetailsUrl = (id, lang, universe) => `${process.env.REACT_APP_VIALMA_API}/radios/${id}?lang=${lang}${universe}`;
export const getAlsoLikedUrl = (id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/playlists/${id}/see-also?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;

//Podcast Urls
export const getPodcastsUrl = (lang, universe, count, anonymous) =>
  `${process.env.REACT_APP_VIALMA_API}/podcast_cat?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}&count=${count}${
    anonymous ? "&anonymous=true" : ""
  }`;

//Chapter Urls
export const getChapterDetails = (id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/chapter/${id}?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getChapterPlaylist = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/radios/${id}?lang=${lang}&ver=2`;
export const getChapterSimilar = (id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/podcast/${id}/similar?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;

//Chapter Urls
export const getCollections = (lang, universe, count, page) =>
  `${process.env.REACT_APP_VIALMA_API}/paginated-collections?lang=${lang}&universe=${
    universe ? universe : CLASSICAL_NAME
  }&page=${page}&perPage=${count}`;

//My Library Urls
export const getUserLikedTracks = (lang) => `${process.env.REACT_APP_VIALMA_API}/customer/favourite_tracks?lang=${lang}`;
export const getFavouritePersonsUrl = `${process.env.REACT_APP_VIALMA_API}/customer/favourite_composers`;
export const getFavouriteVialmaPlaylistsUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/customer/favourite_playlists?lang=${lang}`;
export const getUserPlaylistDetails = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/playlists/${id}/pistes?lang=${lang}&ver=2`;
export const getUserPlaylists = (lang) => `${process.env.REACT_APP_VIALMA_API}/clients/playlists?lang=${lang}`;
export const getUserListeningHistory = (lang, count) =>
  `${process.env.REACT_APP_VIALMA_API}/clients/historique?lang=${lang}${count ? `&page=1&count=${count}` : ""}`;
export const getUserLikedAlbums = (lang, count) =>
  `${process.env.REACT_APP_VIALMA_API}/customer/favourite_albums?lang=${lang}${count ? `&page=1&count=${count}` : ""}`;
export const getUserLikedVideos = (lang, count) =>
  `${process.env.REACT_APP_VIALMA_API}/customer/favourite_videos?lang=${lang}${count ? `&page=1&count=${count}` : ""}`;
export const getUserLikedArticles = (lang) => `${process.env.REACT_APP_VIALMA_API}/customer/favourite_news?lang=${lang}`;
export const getUserLikedCollections = (lang, count) =>
  `${process.env.REACT_APP_VIALMA_API}/customer/favourite_partners?lang=${lang}${count ? `&page=1&count=${count}` : ""}`;
export const likeOrUnlikeAlbum = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/albums/${id}/favourite?lang=${lang}`;
export const likeOrUnlikeVideo = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/videos/${id}/favourite?lang=${lang}`;
export const likeOrUnlikePlaylist = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/playlists/${id}/favourite?lang=${lang}`;
export const likeOrUnlikeCollection = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/partners/${id}/favourite?lang=${lang}`;
export const likeOrUnlikeArticle = (id, lang) => `${process.env.REACT_APP_VIALMA_API}/news/${id}/favourite?lang=${lang}`;

//Main page
export const getWelcomeBannerUrl = (lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/welcome_banner?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getFeaturedCollectionsUrl = (count, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/partners/featured?count=${count}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getDailyAlbumsUrl = (count, universe) =>
  `${process.env.REACT_APP_VIALMA_API_2}/albums/random_advanced?count=${count}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getDailyAlbumsJazzUrl = (count, universe) =>
  `${process.env.REACT_APP_VIALMA_API_2}/albums/random?count=${count}&universe=${universe ? universe : JAZZ_NAME}`;
export const getFeaturedContentUrl = (universe, lang) =>
  `${process.env.REACT_APP_VIALMA_API}/categories/featured-content?universe=${universe ? universe : CLASSICAL_NAME}&lang=${lang}`;
export const getUserPlaylistsHomeUrl = (count, lang) =>
  `${process.env.REACT_APP_VIALMA_API}/clients/recently_edited_playlists?limit=${count}&lang=${lang}`;
export const getRandomComposersByPeriodUrl = (lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/composers/daily_random_by_period_and_country?count=4&lang=${lang}&universe=${universe ? universe : JAZZ_NAME}`;
export const getDailyRandomPersonUrl = (personType, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/${personType}/daily_random?count=4&lang=${lang}&universe=${universe}`;
export const getDailyRandomArtistUrl = (lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/artists/daily_random_editors_pick?count=4&lang=${lang}&universe=${universe}`;
//New URL to replace daily random person (above) for classical
export const getDailyRandomComposerUrl = (lang) =>
  `${process.env.REACT_APP_VIALMA_API}/composers/daily_random_popular?count=4&lang=${lang}&universe=${CLASSICAL_NAME}`;
export const getDailyRandomVideosUrl = (count, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/videos/daily_random?count=${count}&lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getMoreContentFromCategoryUrl = (type, id, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/category/${type}/${id}/content?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}`;
export const getPopularAlbums = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/albums/daily_random_popular?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getPopularPlaylists = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/playlists/daily_random_popular?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getPersonalRecommendationsUrl = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/customers/recommended-from-listening-history?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getAlsoLoveUrl = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/clients/recommended-from-user-liked?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getPopularVideosUrl = (lang, universe, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/most_favourited_videos?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getAlbumCategoriesUrl = (limit, lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/albums/categories?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}${
    limit ? `&limit=${limit}` : ""
  }`;
export const getCollectionContentForHome = (lang, universe) =>
  `${process.env.REACT_APP_VIALMA_API}/collections?lang=${lang}&universe=${universe ? universe : CLASSICAL_NAME}&displayOnHome=true`;

//Giftcard Urls
export const createVoucherUrl = (lang) => `${process.env.REACT_APP_ENSEMBLE_API}/crm/vouchers/?lang=${lang}`;

//Account
export const refreshClientCacheUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/client/refresh?lang=${lang}`;
export const getClientUrlEP = (siteId) => `${process.env.REACT_APP_VIALMA_API}/b2b-sites/${siteId}/client`;
export const getDeleteAccountUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/customers/delete_account?lang=${lang}`;
export const getCancelSubUrl = (lang) => `${process.env.REACT_APP_VIALMA_API}/customers/subscription/cancel?lang=${lang}`;
export const getSetAudioFormatUrl = (audioFormat) => `${process.env.REACT_APP_VIALMA_API}/stream_quality/${audioFormat}?platform=web`;

//Listening History Urls
export const getListeningHistoryUrl = (lang, limit) =>
  `${process.env.REACT_APP_VIALMA_API}/customers/recently-listened-history?lang=${lang}${limit ? `&limit=${limit}` : ""}&universeAsFilter=true`;
