import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { getLoginSelector, getLangSelector } from "modules/Config/selectors";
import ThirdParty, { Google, Github, Apple, Twitter } from "supertokens-auth-react/recipe/thirdparty";
import "react-notifications-component/dist/theme.css";
import { getPartnerPagesSelector, getPartnerSelector } from "modules/Partner/selectors";
import { dispatchSetPartner, dispatchSetPartnerLoading } from "modules/Partner/actions";
import { partnerApiLibraryGetPartnerById } from "partners/partnerUtils/apiLibrary";
import { apiLibraryGetClient } from "utils/apiLibrary/client";
import { dispatchUpdateAccount } from "modules/Account/actions";
import { dispatchLogin } from "modules/Config/actions";
import { SESSION_DEFAULT_LANG } from "utils/localStoreKeys";
import posthog from "posthog-js";

const { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } = process.env;
const initPostHog = () =>
  posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
    persistence: "localStorage+cookie",
    secure_cookie: true,
    person_profiles: "identified_only",
    capture_pageview: false,
  });

const PartnerHOC = ({ partner, setPartner, setPartnerLoading, children, isLogged, pages, lang }) => {
  const dispatch = useDispatch();

  const extractTokenFromURL = (url) => {
    const tokenRegex = /(?:|\?)token=([a-zA-Z0-9]+)/;
    const match = url.match(tokenRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getHomeWidgets = async () => {
      try {
        setPartnerLoading(true);
        const response = await partnerApiLibraryGetPartnerById();
        if (response.configuration.tenant_id === 'julia-fischer') {
          import("./styles/jfStyles.scss")
        } else {
          import("./styles/focalStyles.scss")
        }
        window.sessionStorage.setItem(SESSION_DEFAULT_LANG, response?.defaultLanguage);
        setPartner(response);
      } finally {
        initPostHog();
        setPartnerLoading(false);
      }
    };
    !partner && getHomeWidgets();
  }, [dispatch, partner, setPartner, setPartnerLoading]);
  return partner ? children : null;
};


const mapStateToProps = (state) => ({
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  partner: getPartnerSelector(state),
  pages: getPartnerPagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPartner: (partner) => {
    dispatch(dispatchSetPartner(partner));
  },
  setPartnerLoading: (partnerLoading) => {
    dispatch(dispatchSetPartnerLoading(partnerLoading));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PartnerHOC));
