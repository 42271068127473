import { store } from "App";
import axios from "axios";
import { dispatchSetUserCountry } from "modules/Account/actions";
import { dispatchSetFlacStream, dispatchToggleFlacStream, dispatchUpdateIsFlacEnabled } from "modules/Config/actions";
import api from "utils/apiManager";
import { pendoInitHandler } from "utils/pendo";
import { fetchLang, getIsFlacStream, isLoggedIn } from "utils/storeUtils";
import { getHeaders } from "..";
import { getCancelSubUrl, getClientUrlEP, getDeleteAccountUrl, getSetAudioFormatUrl, getUserCountryUrl, refreshClientCacheUrl } from "../urls";
import { captureException } from "@sentry/react";

export const apiLibraryGetClient = async ({siteId, auth, tenantId, lang}) => {
  const headers = auth
    ? {
        Authorization: auth,
        'X-TENANT-ID': tenantId,
        'Accept-Language': lang
      }
    : getHeaders();
  const getClient = getClientUrlEP(siteId);
  
  try {
    const resp = await axios.get(getClient, { headers });
    store.dispatch(dispatchSetFlacStream(resp?.data?.stream_quality_web === "flac"));
    store.dispatch(dispatchUpdateIsFlacEnabled(!!resp?.data?.subscription?.billing_plan?.allow_flac));
    localStorage.setItem("userLang", resp?.data?.customerLanguage || store.getState().partner.partner.defaultLanguage || "en");
    pendoInitHandler(resp?.data);
    return resp?.data;
  } catch (error) {
    captureException(error, {
      tags: {
        message: "Error - Client's API request failed",
      },
    });
    throw new Error(error);
  }
};

export const apiLibraryToggleAudioQuality = async (audioFormat) => {
  const headers = getHeaders();
  const setAudioFormatUrl = getSetAudioFormatUrl(audioFormat);

  try {
    await axios.post(setAudioFormatUrl, null, { headers });
  } catch (error) {}
};

export const handleToggleAudioQuality = () => {
  const isFlacStream = getIsFlacStream();
  apiLibraryToggleAudioQuality(isFlacStream === true ? "mp3" : "flac");
  store.dispatch(dispatchToggleFlacStream());
};
